import { get } from "lodash"
import classNames from "classnames"
import React, { useContext, useEffect } from "react"

import Layout from "layout"
import Section from "elements/Section"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import FileThumbnail from "elements/UploadDocumentDropzone/FileThumbnail"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "./utils/bookingSummary.module.scss"
import { isBrowser } from "services/general"
import { ConsultationContext } from "../../ConsultationContext/ConsultationContext"

let { parseFormField } = require("services/airtable")

// only used in "medstaff" for now
const PhysicalSummary = ({ pageContext }) => {
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )
  useEffect(() => {
    if (isBrowser()) {
      consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [consultationDispatch])

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) =>
      firstSection.module.localeCompare(secondSection.module) ||
      firstSection.order - secondSection.order
  )

  const editKey = {
    "Personal Information": "/consult/employee#personal-information",
    "Emergency Contact Information": "/consult/employee#emergency-contact",
    "Employee Details": "/consult/employee#employee-details",
    Address: "/consult/employee#address",
    "Medical History": pageContext.backPath,
  }
  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate
    >
      <Container isCentered>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: editKey[section?.section] ? (
                  <EditDetailsButton route={editKey[section?.section]} />
                ) : null,
              }}
            >
              <div className="table-container">
                <table class="table is-fullwidth label-size-input">
                  <tbody>
                    {section.fields.map((field) => {
                      let finalValue = null
                      switch (field.type) {
                        case "select":
                          finalValue = get(consultationState, field.name)
                            ? get(consultationState, field.name)?.label
                            : ""
                          break
                        case "date":
                          finalValue = get(consultationState, field.name)
                            ? `${
                                get(consultationState, field.name)?.month?.label
                              } ${
                                get(consultationState, field.name)?.date?.label
                              }, ${
                                get(consultationState, field.name)?.year.label
                              }`
                            : ""
                          break
                        case "address":
                          finalValue = get(consultationState, field.name)
                            ? `${
                                get(consultationState, field.name)
                                  ?.streetAddress
                              }, ${
                                get(consultationState, field.name)?.city?.label
                              }, ${
                                get(consultationState, field.name)?.province
                                  ?.label
                              }`
                            : ""
                          break
                        case "upload":
                          finalValue = consultationState.documents?.map(
                            (document) => <FileThumbnail file={document} />
                          )
                          finalValue = !finalValue.length && null
                          break
                        case "schedule":
                          finalValue = get(consultationState, field.name)
                            ? `${
                                get(consultationState, field.name)?.day?.label
                              }, ${
                                get(consultationState, field.name)?.time?.label
                              }`
                            : ""
                          break
                        case "checkbox":
                          finalValue = consultationState[field.name].join(", ")
                          break
                        default:
                          finalValue = get(consultationState, field.name)
                          break
                      }

                      if (!!finalValue)
                        return (
                          <tr>
                            <td>{field.summaryLabel}</td>
                            <td
                              className={classNames(
                                "has-text-weight-bold",
                                styles["summary__tableData"]
                              )}
                            >
                              {finalValue}
                            </td>
                          </tr>
                        )
                      return null
                    })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        <ActionButtons
          back={{ label: "Back", link: pageContext.backPath }}
          next={{ label: "Schedule Teleconsult", link: pageContext.nextPath }}
        />
      </Container>
    </Layout>
  )
}

export default PhysicalSummary
